import React from "react";

const About: React.FC = () => (
  <div className="container about">
    <h3>About this PWA</h3>
    <p>This is the &quot;about&quot; page ...</p>
  </div>
);

export default About;
