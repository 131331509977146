import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import axios from 'axios';

import "./App.css";
import About from "./About";
import Home from "./Home";
import Todos from "./components/Todos";

const App: React.FC = () => (
  <>
    <nav className="navbar navbar-expand">
      <a href="/" className="navbar-brand">
        <div className="font-weight-bold brand">
          <img src="logo192.png" alt="logo" className="img-fluid" style={{ width: 40 }} /> JK's PWA
        </div>
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item"><a href="/" className="nav-link">Home</a></li>
          <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
        </ul>
      </div>
    </nav>

    <div className="wrapper">
      <h2>JK's PWA Aplication</h2>
    </div>

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>

    <div className="footer">
      <p>footer here ...</p>
    </div>

  </>
);
export default App;
