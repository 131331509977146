import React, { useEffect, useState } from "react"

const Todos: React.FC<{}> = () => {

const [todos, setTodos] = useState<any[]>([])
  const fetchData = async () => {
    const response = await fetch("https://pwa.webineering.com.au:3001/todos")
    //const response = await fetch("http://localhost:3001/todos")
    const data = await response.json()
    setTodos(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className="container">
        <h4>This is "home"</h4>
        {todos.length > 0 && (
          <ul>
            {todos.map(todo => (
              <li key={todo._id}>{todo.name}</li>
            ))}
          </ul>
        )}
      </div>
    </>
 )
};

export default Todos;
